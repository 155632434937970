import React, { useState } from "react";
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { useToasts } from 'react-toast-notifications';
import Vault from "../../../static/vault.svg";

import "./mailingList.css";

const MailingList = () => {

    const [email, setEmail] = useState("");
    const { addToast } = useToasts();
    
    const handleChange = (event) => {
        setEmail(event.target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        addToMailchimp(email) 
        .then(data => {
            if (data.result.includes("error")) {
                if (data.msg.includes("is already subscribed")) {
                    addToast("Looks like this email is already subscribed.", { appearance: 'warning', autoDismiss: true });
                } else {
                    addToast("Uh oh! Something went wrong, double check the email and please try again later!", { appearance: 'error', autoDismiss: true });
                }

            } else {
                addToast("Nice! You're all signed up for the newsletter.", { appearance: 'success', autoDismiss: true });
            }
        })
        
    }
    
    return (
        <div className="mailing-list-section">
            <div className="mailing-list-details">
                <Vault  className="vault"/>
                <div className="mailing-list-details-text">
                    <h3>Enter Your Email Below!</h3>
                    <p>Sign up for the newsletter to stay updated on the latest content drops and site updates</p>

                </div>
        
            </div>
            <div className="mailing-list-conatiner">
                <form onSubmit={handleSubmit}>
                    <label id="lbl-email" hidden>Email: </label>
                    <input type="email" aria-labelledby="lbl-email" name="email" value={email} onChange={handleChange} className="email-input" required/>
                    <label id="lbl-sign-up" hidden>Sign Up Button</label>
                    <input type="submit" aria-labelledby="lbl-sign-up" name="sign-up" value="Sign Up" className="email-input-button"/>
                </form>
            </div>
      </div>

    );
}

export default MailingList;